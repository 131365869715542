import React from "react"
import styled from "styled-components"

import { Text } from "../core/commonExports"
import { BackButton, Input, InputContainer, InputLabel, mandatoryField, ProgressBarComp } from "./common"
import { Button } from "../core/Button"
import { OnDesktop, OnMobile } from "../DesktopMobileComps"
import Select from "../core/Select"

import { getCompactTypeOfFacility } from "./sharedUtils"
import useDebouncedValue from "../../hooks/useDebouncedValue"
import useAutoSave from "./hooks/useAutoSave"
import useAutoScrollToProceedBtn from "./hooks/useAutoScrollToProceedBtn"
import { getPincodeData } from "../../api/staticDataAPIs"
import { floorValues } from "./sharedData"

import CheckIcon from "../../assets/images/checkIconTransparent.svg"
import loaderGIF from "../../assets/loader.gif"
import InfoGreen from "../../assets/images/info-green.svg"

const InfoContainer = styled.div`
  padding: 10px 20px;
  margin: 16px 0;
  display: flex;
  gap: 10px;
  text-align: left;
  background: #F4FFF9;
  border: 1px solid #57BA70;
  border-radius: 12px;
  @media screen and (max-width: 768px) {
    margin-top: 8px;
  }
`

const infobox = (
  <InfoContainer>
    <InfoGreen />
    <Text style={{margin: "0"}} fontSize="12px" mfontSize="10px" lineHeight="20px" mlineHeight="16px" fontWeight="500" color="#4AA161">
      Your information is safe with us. We only share this data with insurers to get you the best quotes!
    </Text>
  </InfoContainer>
)

const AddressCollection = ({
  address,
  setAddress,
  floor,
  setFloor,
  pincode,
  setPincode,
  city,
  state,
  setCity,
  setStateVal,
  typeOfFacility,
  onFormSubmit,
  onGoBack,
  newTheme,
  autoSaveData,
  isAutoSaving,
  isPopup,
}) => {
  const proceedBtnRef = React.useRef()
  const debouncedPincode = useDebouncedValue(pincode, 500)
  const debouncedAddress = useDebouncedValue(address, 800)
  const debouncedFloor = useDebouncedValue(floor, 800)

  const [fetchingData, setFetchingData] = React.useState(false)
  const isValidPin = debouncedPincode.length === 6

  const compactToF = getCompactTypeOfFacility(typeOfFacility)
  const isGarageShowroom = compactToF === "Motor vehicle garage" || compactToF === "Motor vehicle showroom"

  useAutoSave(() => {
    if (isValidPin || debouncedPincode === '') {
      autoSaveData("pincode", {PIN: debouncedPincode})
    }
  }, [debouncedPincode])

  useAutoSave(() => {
    autoSaveData("address", {Address: debouncedAddress})
  }, [debouncedAddress])

  useAutoSave(() => {
    autoSaveData("floor", {Floor: debouncedFloor})
  }, [debouncedFloor])

  const allInputsFilled = isValidPin && !fetchingData && !isAutoSaving

  useAutoScrollToProceedBtn(
    proceedBtnRef, 
    allInputsFilled, 
    [debouncedPincode]
  )

  React.useEffect(() => {
    let getPinLocation = null

    if (isValidPin) {
      setFetchingData(true)
      getPinLocation = setTimeout(async () => {
        const data = await getPincodeData(debouncedPincode)
        const locations = data?.[0]?.PostOffice
        if (locations?.length) {
          setCity(locations?.[0]?.District)
          setStateVal(locations?.[0]?.State)
        } else {
          setCity('')
          setStateVal('')
        }
        setFetchingData(false)
      }, 1000)
    } else {
      setCity('')
      setStateVal('')
    }

    return () => clearTimeout(getPinLocation)
  }, [debouncedPincode, isValidPin])

  let pincodeResponse = ""

  if (isValidPin) {
    if (fetchingData) {
      pincodeResponse = <img src={loaderGIF} style={{width: "180px", height: "26px", objectFit: "cover", marginLeft: "-65px"}} alt="loading..." />
    } else if (city) {
      pincodeResponse = <span style={{color: "#57BA70", display: "flex", alignItems: "center"}}>
        <CheckIcon style={{marginRight: "0.25rem"}} /> {city}
      </span>
    } else {
      pincodeResponse = ""
    } 
  }

  return (
    <form style={{display: 'flex', flexDirection: 'column', width: "100%", paddingTop: isPopup ? "0.5rem" : "0"}} onSubmit={onFormSubmit}>
      <div style={{display: "flex", gap: "1rem", paddingRight: "1rem"}}>
        <BackButton handleClick={onGoBack} />
        <ProgressBarComp progress="94%" />
      </div>
      <Text style={{alignSelf: "center", textAlign: "center"}} mobileStyles={{margin: "1.5rem 0", marginTop: "2rem"}} desktopStyles={{margin: "1.75rem 0"}} fontSize="18px" mfontSize="16px" lineHeight="22.68px" mlineHeight="18px" color="#C32A1F" fontWeight="600">
        Enter your {getCompactTypeOfFacility(typeOfFacility)} address to know your 
        {!isGarageShowroom ? <br /> : <>&nbsp;</>}
        <strong>RISK FACTOR!</strong> 🚨
      </Text>
      <InputContainer>
        <InputLabel htmlFor="frmPinCode" desktopStyles={{marginBottom: "8px"}}>
          Pin Code {mandatoryField}
        </InputLabel>
        <Input
          placeholder=""
          onInput={setPincode}
          value={pincode}
          type="number"
          name="pincode"
          id="frmPinCode"
          required
          maxLength="6"
        />
        {pincodeResponse && <Text fontSize="12px" mfontSize="12px" lineHeight="17px" style={{margin: "0", float: "left"}} color="#aaa">
          {pincodeResponse}
        </Text>}
      </InputContainer>
      <InputContainer>
        <InputLabel desktopStyles={{marginBottom: "8px"}}>
          Choose Floor No. {mandatoryField}
        </InputLabel>
        <Select
          style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
          desktopStyles={{marginBottom: "8px"}}
          compulsory
          value={floor}
          onChange={selected => setFloor(selected)}
          placeholder="Select option"
        >
          {floorValues.map(floorItem => (
            <option value={floorItem.value} key={floorItem.value}>
              {floorItem.label}
            </option>
          ))}
        </Select>
      </InputContainer>
      <InputContainer>
        <InputLabel desktopStyles={{marginBottom: "8px"}}>
          Address (First line & Landmark) {mandatoryField}
        </InputLabel>
        <Input value={address} onChange={e => setAddress(e.target.value)} placeholder="Building Name / Shop Number and nearest Landmark" />
      </InputContainer>
      <div style={{display: "flex", justifyContent: "center", gap: "0.5rem"}}>
        {city ? <InputContainer style={{flex: "1"}}>
          <InputLabel desktopStyles={{marginBottom: "8px"}}>
            City
          </InputLabel>
          <Input value={city} placeholder="Enter" disabled style={{background: "rgba(235, 235, 235, 1)"}} />
        </InputContainer> : null}
        {state ? <InputContainer style={{flex: "1"}}>
          <InputLabel desktopStyles={{marginBottom: "8px"}}>
            State
          </InputLabel>
          <Input value={state} placeholder="Enter" disabled style={{background: "rgba(235, 235, 235, 1)"}} />
        </InputContainer> : null}
      </div>
      <OnMobile>{infobox}</OnMobile>
      <div ref={proceedBtnRef} />
      <Button
        wide
        label="View Prices"
        type={newTheme ? "flatdesign" : "secondary"}
        uppercaseLabel={false}
        onClick={onFormSubmit}
        disabled={!debouncedAddress || !debouncedFloor || !isValidPin || isAutoSaving}
        style={{alignSelf: "center"}}
      />
      <OnDesktop>{infobox}</OnDesktop>
    </form>
  )
}

export default AddressCollection